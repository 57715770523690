export const shouldUpdateScroll = () => {
  // const { pathname } = location
  // // list of routes for the scroll-to-top-hook
  // const keepScrollPosition = [`/county`, `/state`]
  // // if the new route is part of the list above, scroll to top (0, 0)

  // if (keepScrollPosition.find((r) => pathname.startsWith(r))) {
  //   window.scrollTo(0, 0)
  // }

  // scroll to top on all..

  window.scrollTo(0, 0)

  return false
}
