const theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  maxWidths: {
    default: '1500px',
  },
  fonts: {
    body: 'Quicksand, system-ui, sans-serif',
    heading: 'Quicksand, system-ui, sans-serif',
    monospace: 'Quicksand, "Roboto Mono", monospace',
  },
  fontSizes: [13, 14, 16, 18, 20, 24, 32, 48, 64, 96],
  zIndex: [1, 10, 20, 100, 1000, 1030, 2147483643],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 600,
  },
  lineHeights: {
    body: 1.3,
    heading: 1.125,
  },

  colors: {
    text: '#202124',
    background: '#F8F9FF',
    lightText: '#FFFFFF',
    moderateRisk: '#E2AC44',
    specialEvent: '#ffc0eb',
    caution: '#EE732F',
    disclaimer: '#EAEDFF',
    highRisk: '#EB2828',
    open: '#5BCAC5',
    closed: '#dee2e6',
    iconCircle: '#CDD4FF',
    yellow: '#E2AC44',
    green: '#5BCAC5',
    redOrange: '#EE732F',
    purple: '#8F8CFF',
    purpleLight: '#8F8CFF6B',
    redLight: '#EB28286B',
    pinkLight: '#ffc0eb6B',
    navLink: '#E56846',
    blue: '#1d2d44',
    gray100: '#f8f9fa',
    gray200: '#e9ecef',
    gray300: '#dee2e6',
    gray400: '#ced4da',
    gray500: '#adb5bd',
    gray600: '#8e959c',
    gray700: '#495057',
    gray800: '#343a40',
    gray900: '#212529',
    black: '#000',
  },
  badges: {
    primary: {
      color: 'background',
      bg: 'purple',
      px: 10,
    },
    secondary: {
      color: 'background',
      bg: 'redOrange',
      px: 10,
    },
    success: {
      color: 'background',
      bg: 'pink',
      px: 10,
    },
    outline: {
      color: 'redOrange',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 1px',
      px: 10,
    },
  },
  buttons: {
    primary: {
      cursor: 'pointer',
      padding: 2,
      px: 3,
      borderRadius: 4,
      bg: 'blue',
      fontSize: 1,
      textUnderline: 'none',
      fontFamily: 'body',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
      '&:disabled': {
        opacity: '38%',
        cursor: 'auto',
        fontStyle: 'italic',
        boxShadow: 'none',
        color: 'gray300',
      },
    },
    secondary: {
      variant: 'buttons.primary',
      background: 'gray500',
    },
    link: {
      color: 'purple',
      textDecoration: 'underline',
      bg: 'transparent',
      padding: '0px',
    },
    success: {
      variant: 'buttons.primary',
      bg: 'green',
    },
    white: {
      variant: 'buttons.primary',
      bg: 'white',
      color: 'text',
    },
    naked: {
      // variant: 'buttons.primary',
      bg: 'white',
      color: 'text',
    },
    viewMore: {
      textDecoration: 'underline',
      bg: 'transparent',
      color: 'redOrange',
      fontWeight: '300',
      lineHeight: 'body',
      boxShadow: 'none',
      padding: '0px',
      fontSize: ['12px', 1],
      mb: '0',
      mt: '0',
    },
  },
  forms: {
    checkbox: {
      width: '20px',
      height: '20px',
      fontSize: '10px',
    },

    input: {
      // display: 'block',
      width: '100%',
      fontFamily: 'inherit',
      border: '2px solid rgba(0, 0, 0, 0.2);',
      borderRadius: '50px',
      lineHeight: '40px',
      height: '40px',
      padding: '0 20px',
      backgroundColor: 'white',
      backgroundClip: 'padding-box',
      fontSize: '1.2rem',
      '&:focus': {
        borderColor: 'black',
        boxShadow: (theme) => `inset 0px 1px 1px ${theme.colors.gray200}`,
      },
      '&::placeholder': {
        color: (theme) => `${theme.colors.gray600}`,
        lineHeight: '16px',
        verticalAlign: 'middle',
      },
    },
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
      bg: 'white',
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
      bg: 'white',
    },
    modalInfoCard: {
      backgroundColor: ['lightText', 'transparent'],
      width: '100%',
      borderRadius: '10px',
      padding: ['20px 0px', '30px 0px 0px'],
      boxShadow: 'rgba(234, 237, 255, 0.25) 4px 4px 8px',
      height: 'auto',
      margin: '8px auto',
      position: 'relative',
      '&:first-of-type': {
        marginTop: '0px',
        paddingTop: '0px',
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      a: {
        color: 'purple',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
      h1: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 6,
      },
      h2: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 4,
        mb: 12,
      },
      h3: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 3,
        mb: 12,
      },
      h4: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 2,
        mb: 12,
      },
      h5: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: [2, 3],
        my: '8px',
      },
      h6: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 0,
        my: '8px',
      },
    },
    cardHeader: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: ['22.5px', '30px'],
      fontWeight: 'heading',
      fontSize: [4, 5],
      mb: '0',
      mt: '0',
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: ['16px', '22.5px'],
      fontSize: [0, 3],
    },
    disclaimer: {
      fontSize: ['10px', '12px'],
      margin: 0,
    },
    a: {
      color: 'primary',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
  },

  sizes: {
    large: '1700px',
    containerMaxWidth: '1200px',
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
  breakpoints: ['768px', '1200px', '1450px'],
  responsive: {
    small: 768,
    medium: 1200,
    large: 1450,
    // make sure breakpoints and responsive match
  },
}
// 1120

export default theme
